/*
 * Controller for the view at `views/engage/admin/community_engagement/campaigns/index.html.erb`.
 */

import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {

  static targets = [ "label" ];

  static values = {
    path: String,
    isCampaign: Boolean,
  };

  async toggleCategory(event) {
    // Note: Can't seem to use `preventDefault()` or `stopImmediatePropagation()` below to prevent the checkbox from
    //  toggling in the first place, probably due to our use of a checkbox hack involving a label, which we use
    //  to display our custom checkboxes.
    if (this.hasIsCampaignValue && this.isCampaignValue) {
      if (event.currentTarget.checked) {
        alert("Sorry, but you can't re-enable a campaign once it's been disabled." +
              " Please create a new campaign instead.");
        event.currentTarget.checked = !event.currentTarget.checked;
        return false;
      } else {
        if (!confirm("Once a campaign is disabled, it can never be re-enabled, and to send this message to more" +
                     " people you will need to create a new campaign. Disable this campaign?")) {
          event.currentTarget.checked = !event.currentTarget.checked;
          return false;
        }
      }
    }

    // When a hidden category is enabled, it is also un-hidden. So we need to remove the class-based styling for hidden
    // categories.
    if (event.currentTarget.checked) {
      this.labelTarget.classList.remove("c-cad-categories__hidden-category-label");
    }

    const request = new FetchRequest("PUT", this.pathValue, {
      body: { is_enabled: event.currentTarget.checked ? "1" : "0" },
    });
    const response = await request.perform();
    if (!response.ok) {
      console.log("Error toggling category.");
    }
  }
}
