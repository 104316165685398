/*
* Handles input visibility in /views/native_root_admin/agency/identity_providers/_form.html.erb
*/

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["clientID", "clientSecret", "identityProviderType"];

  connect() {
    if (this.hasIdentityProviderTypeTarget) {
      this.handleChangeIdentityProviderType();
    }
  }

  handleChangeIdentityProviderType() {
    this.disableAndHideInputs(this.clientIDTarget, this.clientSecretTarget);
    
    // identityProviderType value of 3 = "OIDC" Provider Type
    // Show additional OIDC Config fields
    if (this.identityProviderTypeTarget.value == 3) {
      this.enableAndShowInputs(this.clientIDTarget, this.clientSecretTarget);
    }
  }

  disableAndHideInputs(...args) {
    args.forEach(element => {
      element.style.display = 'none';
      const inputsArray = element.getElementsByTagName('input');
      for (const input of inputsArray) {
        input.disabled = true;
      }
    });
  }

  enableAndShowInputs(...args) {
    args.forEach(element => {
      element.style.display = 'inline';
      const inputsArray = element.getElementsByTagName('input');
      for (const input of inputsArray) {
        input.disabled = false;
      }
    });
  }
}
