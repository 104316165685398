/*
 * The targets of this controller will be cycled through, displaying one at a time. The previous one is faded out
 * and the new one is faded in.
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = ["cycling"];

  static values = {
    displayedElementIndex: { type: Number, default: 0 },
    durationSeconds: { type: Number, default: 5 },
  };

  initialize() {
    this.boundToggleCyclingBasedOnTabVisibility = this.toggleCyclingBasedOnTabVisibility.bind(this);
    // Don't run the timers when the browser tab isn't active. Due to the browser unassigning processing resources
    // from an inactive tab, intervals cannot be relied on to maintain the correct state when the tab isn't active.
    document.addEventListener("visibilitychange", this.boundToggleCyclingBasedOnTabVisibility);

    // Don't cycle if there are fewer than two elements.
    if (this.cyclingTargets.length < 2) {
      this.disconnect();
      return;
    }

    this.hideAllCyclingTargets();
    this.cyclingTargets[0].classList.remove("hidden");
    this.startCycling();
  }

  // Fades out one element, removes it from page display, adds the next element to page display,
  // and fades that next element in.
  displayedElementIndexValueChanged() {
    this.fadeTimerInterval = setInterval(() => {
      if (this.previouslyDisplayedElement().style.opacity > 0) {
        this.previouslyDisplayedElement().style.opacity
          = parseFloat(this.previouslyDisplayedElement().style.opacity) - 0.05;
      } else {
        clearInterval(this.fadeTimerInterval);
        this.previouslyDisplayedElement().classList.add("hidden");
        this.currentlyDisplayedElement().classList.remove("hidden");
        this.fadeTimerInterval = setInterval(() => {
          if (this.currentlyDisplayedElement().style.opacity < 1) {
            this.currentlyDisplayedElement().style.opacity
              = parseFloat(this.currentlyDisplayedElement().style.opacity) + 0.05;
          } else {
            clearInterval(this.fadeTimerInterval);
          }
        }, 10);
      }
    }, 10);
  }

  previouslyDisplayedElement() {
    let elementIndex = this.displayedElementIndexValue - 1;
    if (elementIndex < 0) {
      elementIndex = this.cyclingTargets.length - 1;
    }
    return this.cyclingTargets[elementIndex];
  }

  currentlyDisplayedElement() {
    return this.cyclingTargets[this.displayedElementIndexValue];
  }

  hideAllCyclingTargets() {
    this.cyclingTargets.forEach((element) => {
      element.classList.add("hidden");
      element.style.opacity = 0;
    });
  }

  toggleCyclingBasedOnTabVisibility() {
    if (document.hidden) {
      this.stopCycling();
    } else {
      // When the tab becomes active again, make the proper element visible and restart the cycling.
      this.hideAllCyclingTargets();
      this.currentlyDisplayedElement().style.opacity = 1;
      this.currentlyDisplayedElement().classList.remove("hidden");
      this.startCycling();
    }
  }

  startCycling() {
    this.cycleTimerInterval = setInterval(() => {
      this.displayedElementIndexValue++;
      if (this.displayedElementIndexValue >= this.cyclingTargets.length) {
        this.displayedElementIndexValue = 0;
      }
    }, this.durationSecondsValue * 1_000);
  }

  stopCycling() {
    clearInterval(this.cycleTimerInterval);
    clearInterval(this.fadeTimerInterval);
  }

  disconnect() {
    this.stopCycling();
    document.removeEventListener("visibilitychange", this.toggleCyclingBasedOnTabVisibility);
  }
}
