/*
 * Powers the native frontend application modal.
 * @see views/layouts/new.html.erb
 */

import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = [ "title", "window", "closeButton" ];

  static values = {
    title: { type: String, default: "Engage" },
  };

  open() {
    this.titleTarget.innerText = this.titleValue;
    this.element.classList.remove("hidden");
  }

  close(event) {
    if (event.type === "click"
        && this.windowTarget.contains(event.target)
        && !this.closeButtonTarget.contains(event.target)) {
      return; // Don't close the modal if the user clicked inside of the modal, unless it was on the close button.
    }
    event.preventDefault();
    event.stopPropagation();
    this.element.classList.add("hidden");
  }
}
