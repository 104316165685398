import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    // Where to search for content when using `trackContent`
    "content",
  ];

  static values = {
    // Name of event to log
    track: String,
    // Include this to track event properties (used with `track`)
    trackProperties: Object,
    // Name of event to log on element load (don't use `track` with this)
    trackOnLoad: String,
    // { EventName: Content } object, for tracking `EventName` in Mixpanel when `Content` exists in div,
    //  as determined by #indexOf (don't use `track` with this)
    trackContent: Object,
  };

  initialize() {
    // This functionality has been deleted.
  }

  track() {
    // This functionality has been deleted.
  }

  // Track event as the result of a checkbox change.
  //  Appends " on" or or " off" to the `track` value, as appropriate.
  trackCheckbox(event) {
    // This functionality has been deleted.
  }
}
