/*
 * Sets the attached element's opacity to 0 (invisible) and then smoothly fades it to 1 opacity (solid).
 */

import BaseController from "./base_controller";

export default class extends BaseController {
  initialize() {
    this.element.style.opacity = 0;
    this.fadeIn();
  }

  disconnect() {
    clearInterval(this.timerInterval);
  }

  fadeIn() {
    this.timerInterval = setInterval(() => {
      if (this.element.style.opacity < 1) {
        this.element.style.opacity = parseFloat(this.element.style.opacity) + 0.05;
      } else {
        clearInterval(this.timerInterval);
      }
    }, 10);
  }
}
