import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // Perform a `fetch` and replace a `target`'s inner HTMl with the response text.
  fetchForTarget(url, target, append = false) {
    return fetch(url)
      .then(response => this.handleFetchErrors(response, target))
      .then(response => response.text())
      .then(html => {
        if (append) {
          // Can't use `target.innerHTML += html` here because the selects' HTML does not update when the select
          //  is changed, so inserting HTML by modifying `innerHTML` would cause the selects to reset.
          target.insertAdjacentHTML("beforeend", html);
        } else {
          target.innerHTML = html;
        }
      });
  }

  // Display a generic error message in the `target` div if the `response` is not okay.
  handleFetchErrors(response, target) {
    if (!response.ok) {
      target.innerHTML = "<p>There was a problem loading this data.</p>";
      throw Error(response.statusText);
    } else {
      return response;
    }
  }

  // Generate a random alphanumeric string of a given `length`.
  randomString(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
