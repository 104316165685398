/**
 * ⚠️ ⚠️ ⚠️ THIS FILE IS NOT USED. ⚠️ ⚠️ ⚠️
 * I started writing this controller for the Froala Editor before realizing that Froala 2.x.x has a hard jQuery dep,
 * and we don't negotiate with <s>terrorists</s> jQuery.
 * Leaving this file here for now, since if we upgrade to 4.x.x I might be able to keep some of this.
 */


import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = [ "wrapper" ];

  static values = {
    variables: Object,
  };

  initialize() {
    const wrapper = this.wrapperTarget;
    const variables = this.variablesValue;
    const textarea = wrapper.querySelector("textarea");
    const is_sms = wrapper.classList.contains("sms");
    const is_letterhead = $wrapper.hasClass("letterhead");
    const is_inside_modal = !!wrapper.closest(".modal");

    // Initialize Froala custom dropdown
    FroalaEditor.RegisterCommand('Insert Variable', {
      title: 'Insert Variable',
      type: "dropdown",
      options: variables, // Disabled if HTML option is on
      focus: true,
      undo: true,
      refreshOnShow: function ($btn, $dropdown) {
        // Scroll to top on opening dropdown
        if (wrapper.querySelector(".fr-dropdown-content")) {
          wrapper.querySelector(".fr-dropdown-content").scrollTop(0);
        }
      },
      callback: function (cmd, val, params) {
        this.html.insert(val)
      }
    });

    // Set SMS toolbar
    const sms_toolbar = [
      'Insert Variable', // Add the custom toolbar
      'undo',
      'redo'
    ];

    // Set email toolbar
    const email_toolbar = [
      'Insert Variable', // Add the custom toolbar
      'fontSize',
      'paragraphFormat',
      'color',
      'bold',
      'italic',
      'underline',
      'align',
      'formatOL',
      'formatUL',
      'outdent',
      'indent',
      'insertLink',
      'undo',
      'redo',
      'clearFormatting'
    ];
    const toolbar = is_sms ? sms_toolbar : email_toolbar;

    // Initialize Froala with its toolbar
    // textarea.froalaEditor({
    textarea.froalaEditor({

      // License key
      key: 'LC2A1B1A1sB4E4A3B12B3A7C6F2F4A3fhltzmtdakH3fij==',

      // Set Froala toolbar for different screen size
      toolbarButtons: toolbar,
      toolbarButtonsMD: toolbar,
      toolbarButtonsSM: toolbar,
      toolbarButtonsXS: toolbar,

      theme: "gray",
      fontFamilySelection: true,
      fontSizeSelection: true,
      fontSizeDefaultSelection: is_letterhead ? "12" : "14",
      paragraphFormatSelection: !is_letterhead,
      paragraphFormat: {
        N: 'Normal',
        H1: 'Heading 1',
        H2: 'Heading 2',
        H3: 'Heading 3',
        H4: 'Heading 4',
        H5: 'Heading 5',
        H6: 'Heading 6'
      },

      tabSpaces: 4, // Use Tab key for tab spaces
      enter: is_sms ? FroalaEditor.ENTER_BR : FroalaEditor.ENTER_P,
      pastePlain: !is_sms,
      heightMin: 250,
      heightMax: 500,
      height: is_inside_modal ? 250 : null,
      placeholderText: 'Type something here...',
      iframe: true,
      iframeStyle: ` BODY { padding: 1em; font-size: ${is_letterhead ? "12" : "14"}px; } .fr-box.fr-basic .fr-element.fr-view, P { background: transparent; font-size: ${is_letterhead ? "12" : "14"}px; }`
    });

    const iframe = $element.find("IFRAME");

    // Wait for animation to complete before set the iframe properly
    if (is_inside_modal) {
      wrapper.parents(document).on('shown.bs.modal', setIframeProperly);
    } else {
      setIframeProperly();
    }

    function setIframeProperly() {
      textarea.froalaEditor('size.syncIframe');
      textarea.froalaEditor('events.focus');
    }

    wrapper.removeClass("invisible");
  }
}
