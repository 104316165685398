/*
 * Handles previewing thank you HTML in /views/native_root_admin/surveys/_form.html.erb
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "description", "surveyType", "CAD", "standalone" ];
  static values = {
    thankYouSurveyPath: String,
  };

  connect() {
    if (this.hasSurveyTypeTarget) {
      this.handleChangeSurveyType();
    }
  }

  thankyou() {
    const preview_thank_you = this.descriptionTarget.value ? encodeURIComponent(this.descriptionTarget.value) : "1";
    window.open(this.thankYouSurveyPathValue.replace("query", preview_thank_you));
  }

  handleChangeSurveyType() {
    this.disableAndHideInputs(this.CADTarget, this.standaloneTarget);

    switch (parseInt(this.surveyTypeTarget.value)) {
      case 7:  // CAD Responder ID
        this.enableAndShowInputs(this.CADTarget);
        break;
      case 19: // Standalone survey ID
        this.enableAndShowInputs(this.standaloneTarget);
        break;
    }
  }

  disableAndHideInputs(...args) {
    args.forEach(element => {
      element.style.display = 'none';
      const inputsArray = element.getElementsByTagName('input');
      for (const input of inputsArray) {
        input.disabled = true;
      }
    });
  }

  enableAndShowInputs(element) {
    element.style.display = 'inline';
    const inputsArray = element.getElementsByTagName('input');
    for (const input of inputsArray) {
      input.disabled = false;
    }
  }
}