import BaseController from "../base_controller";

export default class extends BaseController {

  // When the checkbox in the "Use Custom?" table column is changed, enable or disable the input in the next cell
  //  (the next cell is in the "Custom Value" column).
  handleUseCustomToggle(event) {
    let nextTableCell = event.currentTarget.closest("TD").nextElementSibling;
    nextTableCell.querySelectorAll("input, select, textarea").forEach((inputElement) => {
      inputElement.disabled = !event.currentTarget.checked;
    });
  }
}
