/*
 * Controller for the form in `views/native_root_admin/agencies/_form.html.erb`.
 */

import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";

export default class extends Controller {
  static targets = [ "state", "counties", "demoAgencyToggle", "demoAgencyForm" ];
  static values = {
    defaultCountyId: Number,
    stateCountiesPath: String,
  };

  connect() {
    this.getCountyList(this.defaultCountyIdValue);
    this.toggleDemoAgency();
  }

  async getCountyList(defaultCountyId = 0) {
    if (!this.stateTarget.value) return null;
    const path = this.stateCountiesPathValue.replace("*", this.stateTarget.value);
    const request = new FetchRequest("GET", path);
    const response = await request.perform();
    if (!response.ok) {
      console.log("Error getting county list from state id");
      return;
    }

    const data = await response.json;
    this.countiesTarget.innerHTML = "";
    // Adding the no selected option
    const option = document.createElement("option");
    option.text = "Select...";
    option.value = "";
    this.countiesTarget.appendChild(option);

    data.body.map(county => {
      const option = document.createElement("option");
      option.text = county.name;
      option.value = county.id;
      if (defaultCountyId && defaultCountyId === county.id) {
        option.selected = "selected";
      }
      this.countiesTarget.appendChild(option);
    });
  }

  toggleDemoAgency() {
    if (!this.hasDemoAgencyToggleTarget) return null;
    if (this.demoAgencyToggleTarget.checked) {
      this.demoAgencyFormTarget.style.display = "inline";
      const inputsArray = this.demoAgencyFormTarget.getElementsByTagName("input");
      for (const input of inputsArray) {
        input.disabled = false;
      }
    } else {
      this.demoAgencyFormTarget.style.display = "none";
      const inputsArray = this.demoAgencyFormTarget.getElementsByTagName("input");
      for (const input of inputsArray) {
        input.disabled = true;
      }
    }
  }
}