/*
 * Makes a table row clickable. Also changes the mouse cursor appropriately.
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // If the table row contains a link/button, mark the link/button as an anchor target to prevent the redirect.
  static targets = [ "anchor", "hiddenAnchor" ];
  static values = {
    url: String, // The URL to navigate to.
    turboFrame: String, // Optional: Render the result in this turbo frame.
  };

  initialize() {
    if (this.urlValue) {
      this.element.classList.add("pointer"); // Classic Engage
      this.element.classList.add("cursor-pointer"); // Tailwind
    }
  }

  async clickRow() {
    if (window.getSelection().toString().length !== 0) return;

    if (!this.hasAnchorTarget || !this.anchorTargets.some(e => e.contains(event.target))) {
      Turbo.visit(this.urlValue, { frame: this.turboFrameValue });
    }
  }
}
