/*
 * Used to apply a left-to-right-first masonry layout. As of right now, CSS only supports top-to-bottom-first masonry
 * layout. There is flag support in Firefox, but that's a far cry from being able to use it everywhere. So we use a
 * JS dependency.
 * More info: https://spope.github.io/MiniMasonry.js/
 */

import { Controller } from "@hotwired/stimulus";
import MiniMasonry from "minimasonry";

export default class extends Controller {
  initialize() {
    // Note: This controller could be extended to accept MiniMasonry parameters as Stimulus values. However, at the
    // time of this writing, this controller is only used for the feedback board. So I apply parameters for the
    // feedback board right here.
    this.miniMasonry = new MiniMasonry({
      baseWidth: 512, // Target width of elements.
      container: this.element, // Container selector or HTMLElement. Required.
      gutter: 16, // Width / height of gutter between elements. Use gutterX / gutterY to set different values.
      surroundingGutter: false, // Set left gutter on first columns and right gutter on last.
    });

    // Redo the masonry layout if an element is added or removed. For example, a feedback board response being
    // deleted.
    this.boundRelayout = this.relayout.bind(this);
    this.element.addEventListener("DOMNodeInserted", this.boundRelayout);
    this.element.addEventListener("DOMNodeRemoved", this.boundRelayout);
  }

  relayout() {
    // Need a little timeout or layout will be called too soon. Some kind of race condition.
    setTimeout(() => {
      this.miniMasonry.layout();
    }, 100);
  }
}
