import BaseController from "./base_controller";

export default class extends BaseController {
  static values = {
    content: String,
  };

  initialize() {
    App.$tippy(this.element, { content: this.contentValue });
  }
}
