/*
 * Toggles hidden fields
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "hideable", "toggle" ];

  initialize() {
    this.toggle();
  }

  toggle() {
    this.hideableTargets.forEach(element => {
      if (this.toggleTarget.checked) {
        element.classList.remove("hidden");
      } else {
        element.classList.add("hidden");
      }
    });
  }
}
