/*
 * Controller for the community engagement module's campaign recipients views.
 *
 * Note that this file has been changed extensively during development and is a good candidate for simplification.
 * Things are probably a bit messier than they need to be as a result of the evolving requirements and changes
 * during development.
 *
 * UPDATE: THIS SHOULD BE CONVERTED TO USE TURBO.
 */

import BaseController from "../../../base_controller";
import {question} from "dns-packet";

export default class extends BaseController {

  static targets = [ "selectsDiv", "questionOptionDiv", "surveySelect", "deleteQuestionOptionDivLink",
                     "questionDiv", "questionSelect", "answerDiv",
                     "filterTypeRadioButton", "filterTypeTextDiv", "addQuestionOptionLink", "filtersDiv",
                     "allSubscribersCheckbox"];

  static values = {
    questionOptionDivBaseUrl: String,
    answerSelectBaseUrl: String,
  }

  initialize() {
    this.setFilterTypeTextDivs();
    this.setVisibilityOfAddQuestionOptionLink();
    this.setFiltersVisibility();
  }

  setFilterTypeTextDivs() {
    let checkedValue = this.filterTypeRadioButtonTargets.find((e) => e.checked).value;
    let color = checkedValue === "all" ? "blue" : "green";
    this.filterTypeTextDivTargets.slice(0, -1).forEach((div) => {
      div.innerHTML = `<span class="py-1 px-3 bg-${color}-100 text-${color}-500 rounded">${checkedValue === "all" ? "AND": "OR"}</span>`;
    });
    let lastFilterTypeTextDivTarget = this.filterTypeTextDivTargets.at(-1);
    if (lastFilterTypeTextDivTarget) {
      lastFilterTypeTextDivTarget.innerHTML = "";
    }
  }

  addQuestionSelect(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.fetchForTarget(`${this.questionOptionDivBaseUrlValue}/${this.surveySelectTarget.value}`, this.selectsDivTarget, true).then(() => {
      this.setFilterTypeTextDivs();
    });
  }

  deleteQuestionSelect(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    let targetIndex = this.deleteQuestionOptionDivLinkTargets.indexOf(event.currentTarget);
    this.questionOptionDivTargets[targetIndex].remove();
    this.setFilterTypeTextDivs();
  }

  handleChangeSurvey(event) {
    this.questionOptionDivTargets.forEach((div) => { div.remove(); });
    this.setVisibilityOfAddQuestionOptionLink();
  }

  setVisibilityOfAddQuestionOptionLink() {
    if (this.surveySelectTarget.value) {
      this.addQuestionOptionLinkTarget.classList.remove("hidden");
    } else {
      this.addQuestionOptionLinkTarget.classList.add("hidden");
    }
  }

  setFiltersVisibility() {
    this.allSubscribersCheckboxTarget.checked ? this.filtersDivTarget.classList.add("hidden") : this.filtersDivTarget.classList.remove("hidden");
  }

  changeQuestion(event) {
    let answerDivTargetIndex = this.questionDivTargets.indexOf(event.currentTarget.parentElement);
    let questionSelectTargetIndex = this.questionSelectTargets.indexOf(event.currentTarget);
    if (this.questionSelectTargets[questionSelectTargetIndex].value) {
      this.fetchForTarget(`${this.answerSelectBaseUrlValue}/${this.questionSelectTargets[questionSelectTargetIndex].value}`,
        this.answerDivTargets[answerDivTargetIndex]);
    } else {
      // Hide the answer div if no question is selected
      this.answerDivTargets[answerDivTargetIndex].innerHTML = "";
    }
  }
}
