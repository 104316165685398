/*
 * Attach this controller to a turbo frame to reload it every 60 seconds.
 * Could be extended to accept a value, thereby supporting intervals other than 60 seconds.
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static values = {
    afterSeconds: { type: Number, default: 600 },
  };

  initialize() {
    this.timerInterval = setInterval(() => {
      this.element.reload();
    }, this.afterSecondsValue * 1000);
  }

  disconnect() {
    clearInterval(this.timerInterval);
  }
}
