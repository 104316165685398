/*
 * 1. Dump data into this controller.
 * 2. Chart.js chart comes out.
 * See: https://www.chartjs.org/docs/latest/
 */

import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import "chartjs-adapter-moment"; // Would be good to replace with lighter-weight adapter if we ever get rid of moment.
import autocolors from "chartjs-plugin-autocolors";

export default class extends Controller {

  static targets = [ "canvas", "exportOptions" ];

  static values = {
    dataset: Object,
  }

  initialize() {
    this.registerPlugins();

    // This crazy map reduce simply checks if any data exists for the chart.
    if (this.datasetValue.data.datasets.map((d) => d.data.length).reduce((p, a) => p + a, 0) === 0) {
      this.canvasTarget.outerHTML = "<p>No data for this chart.</p>";
    } else {
      this.drawChart();
    }
  }

  drawChart() {
    // Spotlight v3.1: Validate that required data is included in the dataset.
    let data = this.datasetValue;

    // Disable decimal places (floats) on axes.
    data.options.scale = data.options.scale || {};
    data.options.scale.ticks = data.options.scale.ticks || {};
    data.options.scale.ticks.precision = 0;

    // Configure percentage tooltips for doughnut charts only.
    if (data.type === "doughnut") {
      data.options.plugins = data.options.plugins || {};
      data.options.plugins.tooltip = data.options.plugins.tooltip || {};
      data.options.plugins.tooltip.callbacks = data.options.plugins.tooltip.callbacks || {};
      data.options.plugins.tooltip.callbacks.label = (value) => {
        return ((value?.parsed || 0) * 100).toFixed(2) + "%";
      };
    }

    // Truncate Long Labels for Bar Charts
    if (data.type === "bar") {
      data.options.scales = data.options.scales || {};
      data.options.scales.x = data.options.scales.x || {};
      data.options.scales.x.ticks = data.options.scales.x.ticks || {};
      data.options.scales.x.ticks.callback = function(value, index, ticks) {
        let label_value = this.getLabelForValue(value);
        if (label_value.length > 20) {
          return label_value.substr(0, 17) + "...";
        }
        else {
          return label_value;
        }
      };

      data.options.scales = data.options.scales || {};
      data.options.scales.y = data.options.scales.y || {};
      data.options.scales.y.ticks = data.options.scales.y.ticks || {};
      data.options.scales.y.ticks.callback = function(value, index, ticks) {
        let label_value = this.getLabelForValue(value);
        if (label_value.length > 20) {
          return label_value.substr(0, 17) + "...";
        }
        else {
          return label_value;
        }
      };
    }

    new Chart(this.canvasTarget, data);
  }

  toggleExportOptions(event) {
    event.preventDefault();
    event.stopPropagation();
    this.exportOptionsTarget.classList.toggle("hidden");
  }

  registerPlugins() {
    Chart.register(autocolors);

    // Display text in the center of doughnut charts.
    // Adapted from source: https://stackoverflow.com/a/43026361/1196465
    Chart.register({
      id: "doughnut-centertext",
      beforeDraw: function(chart) {
        if (chart.config?.options?.elements?.center) {
          // Get ctx from string
          let ctx = chart.ctx;

          // Get options from the center object in options
          let centerConfig = chart.config.options.elements.center;
          let fontStyle = centerConfig.fontStyle || "Open Sans, sans-serif";
          let txt = centerConfig.text;
          let color = centerConfig.color || "rgb(30, 40, 59)";
          let maxFontSize = centerConfig.maxFontSize || 75;
          let sidePadding = centerConfig.sidePadding || 30;
          let sidePaddingCalculated = (sidePadding / 100)
                                      * (chart._metasets[chart._metasets.length-1].data[0].innerRadius * 2);
          // Start with a base font of 30px
          ctx.font = "30px " + fontStyle;

          // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          let stringWidth = ctx.measureText(txt).width;
          let elementWidth = (chart._metasets[chart._metasets.length-1].data[0].innerRadius * 2)
                             - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          let widthRatio = elementWidth / stringWidth;
          let newFontSize = Math.floor(30 * widthRatio);
          let elementHeight = (chart._metasets[chart._metasets.length-1].data[0].innerRadius * 2);

          // Pick a new font size so it will not be larger than the height of label.
          let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
          let minFontSize = centerConfig.minFontSize;
          let lineHeight = centerConfig.lineHeight || 25;
          let wrapText = false;

          if (minFontSize === undefined) {
            minFontSize = 20;
          }

          if (minFontSize && fontSizeToUse < minFontSize) {
            fontSizeToUse = minFontSize;
            wrapText = true;
          }

          // Set font settings to draw it correctly.
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          // Two extra pixels makes it look a bit more centered.
          let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2) + 2;
          ctx.font = fontSizeToUse + "px " + fontStyle;
          ctx.fillStyle = color;

          if (!wrapText) {
            ctx.fillText(txt, centerX, centerY);
            return;
          }

          let words = txt.split(" ");
          let line = "";
          let lines = [];

          // Break words up into multiple lines if necessary
          for (let n = 0; n < words.length; n++) {
            let testLine = line + words[n] + " ";
            let metrics = ctx.measureText(testLine);
            let testWidth = metrics.width;
            if (testWidth > elementWidth && n > 0) {
              lines.push(line);
              line = words[n] + " ";
            } else {
              line = testLine;
            }
          }

          // Move the center up depending on line height and number of lines
          centerY -= (lines.length / 2) * lineHeight;

          for (let n = 0; n < lines.length; n++) {
            ctx.fillText(lines[n], centerX, centerY);
            centerY += lineHeight;
          }
          //Draw text in center
          ctx.fillText(line, centerX, centerY);
        }
      }
    });
  }
}
