/*
 * For PaginationComponent.
 */

import BaseController from "./base_controller";

export default class extends BaseController {
  // Display a loading indicator if the load takes a bit.
  indicateLoading(event) {
    setTimeout((target) => {
      target.innerHTML = "Loading...";
    }, 250, event.currentTarget);
  }
}
