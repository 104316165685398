/*
 * Used to handle character limits on text questions.
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "textArea", "limitSpan", "limitCharactersRemainingSpan" ];

  static values = {
    characterLimit: Number,
  };

  initialize() {
    // Timeout looks to be required, probably to avoid a race condition with whatever code is updating the text area
    //   with any previously-entered text (garlic, I think). Without this, the character count won't update
    //   appropriately, since it seems to measure the text area value length before the text area is popualted
    //   with previously-entered text.
    setTimeout(() => {this.updateCharactersRemaining();}, 50);
  }

  updateCharactersRemaining() {
    if (this.characterLimitValue === null || !this.hasLimitSpanTarget) return;
    this.limitCharactersRemainingSpanTarget.innerHTML = this.characterLimitValue - this.textAreaTarget.value.length;
    if (this.textAreaTarget.value.length >= (this.characterLimitValue - 50)) {
      this.limitSpanTarget.classList.add("text-error");
    } else {
      this.limitSpanTarget.classList.remove("text-error");
    }
    if (this.textAreaTarget.value.length >= this.characterLimitValue) {
      this.limitSpanTarget.classList.add("font-bold");
    } else {
      this.limitSpanTarget.classList.remove("font-bold");
    }
  }
}
