/*
 * Controller for the form in `views/engage/admin/system/reports/_form.html.erb`.
 */

import { Controller } from "@hotwired/stimulus";
import moment from "moment";

export default class extends Controller {

  static targets = [ "automatedDigestTaskTypeIdSelect", "surveyGroup", "divisionGroup", "pdfAndSpreadsheetGroups",
                     "finalRunTimestampInput", "lastGeneratedTimestampInput", "sendFrequencySelect",
                     "enabledCheckbox", "onlySendOnceCheckbox" ];

  static values = {
    isAdmin: Boolean,
  };


  initialize() {
    this.updateDisplay();

    // This effectively disables click events on these fields when the field is readOnly. This is done to prevent
    //  the bootstrap datetimepicker from being usable when the field is readOnly. We have to use readOnly instead
    //  of disabled (which would disable the datetimepicker), because disabled fields aren't sent with HTTP requests,
    //  and we need these fields to be included in the POST and PATCH requests even though the the fields aren't
    //  editable. So basically, this is required to have our cake an eat it too, unless we implement some hidden
    //  fields magic, and I felt it was more reasonable to handle all this JS-related stuff in this JS file rather
    //  than mess around with hidden fields.
    this.onlySendOnceRelatedFields = [];
    if (this.hasFinalRunTimestampInputTarget) this.onlySendOnceRelatedFields.push(this.finalRunTimestampInputTarget);
    if (this.hasLastGeneratedTimestampInputTarget) this.onlySendOnceRelatedFields.push(this.lastGeneratedTimestampInputTarget);
    if (this.hasSendFrequencySelectTarget) this.onlySendOnceRelatedFields.push(this.sendFrequencySelectTarget);
    this.onlySendOnceRelatedFields.forEach((field) => {
      field.addEventListener("mousedown", this.disableClicksIfReadOnlyHandler.bind(this));
    });
  }

  submitForm(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.enabledCheckboxTarget.checked && this.isAdminValue && !this.onlySendOnceCheckboxTarget.checked) {
      if (confirm("Continuing could send emails immediately, depending on the options you have selected below." +
                  " Please check the report period, report period start, and \"don't send after\" date below to" +
                  " ensure you won't be generating more emails than you're expecting. Continue?")) {
        event.target.submit();
      }
    } else {
      event.target.submit();
    }
  }

  // Show and hide certain parts of the form depending on the selected task type.
  updateDisplay() {
    if (this.hasAutomatedDigestTaskTypeIdSelectTarget) {
      if (this.automatedDigestTaskTypeIdSelectTarget.value === "2") {
        this.surveyGroupTarget.classList.add("hidden");
        if (this.hasDivisionGroupTarget) { // May not be rendered if agency doesn't have non-default divisions
          this.divisionGroupTarget.classList.add("hidden");
        }
        this.pdfAndSpreadsheetGroupsTarget.classList.remove("hidden");
        if (this.hasSendFrequencySelectTarget) {
          // Users can select send frequency for Automated Digests.
          this.setReadOnlyAndStyle(this.sendFrequencySelectTarget, false);
        }
      } else {
        this.surveyGroupTarget.classList.remove("hidden");
        if (this.hasDivisionGroupTarget) { // May not be rendered if agency doesn't have non-default divisions
          this.divisionGroupTarget.classList.remove("hidden");
        }
        this.pdfAndSpreadsheetGroupsTarget.classList.add("hidden");
        if (this.hasSendFrequencySelectTarget) {
          // DSUEs should have their send frequency locked to "1 day".
          this.sendFrequencySelectTarget.value = 86400; // The value for the "1 day" option.
          this.setReadOnlyAndStyle(this.sendFrequencySelectTarget, true);
        }
      }
    }
  }

  // When the "Only send once" checkbox is checked, set the final run text input to readOnly and automatically set its
  // value to an appropriate date in the future, based on the values of the send frequency and last generated fields.
  //  When the "Only send once" checkbox is unchecked, clear the final run text input and enable it.
  handleOnlySendOnceToggle(event) {
    if (event.currentTarget.checked) {
      let lastGeneratedAt = moment(this.lastGeneratedTimestampInputTarget.value, "YYYY-MM-DD HH:mm");
      if (!lastGeneratedAt.isValid()) {
        event.currentTarget.checked = false;
        alert("You must enter a valid report period start date first.");
        return;
      }

      let finalRunAt = lastGeneratedAt;

      // No need for a guard statement checking `this.hasSendFrequencySelectTarget` here, because the only-send-once
      //  toggle is only rendered for system accounts, who will also have the send-frequency toggle rendered.
      this.addRailsSecondsStringToMoment(this.sendFrequencySelectTarget.value, finalRunAt);

      this.setOnlySendOnceRelatedFieldsReadonlyTo(true);
      this.finalRunTimestampInputTarget.value = finalRunAt.format("YYYY-MM-DD HH:mm");
    } else {
      this.setOnlySendOnceRelatedFieldsReadonlyTo(false);
      this.finalRunTimestampInputTarget.value = "";
    }
  }

  // Set readOnly on the the inputs related to #handleOnlySendOnceToggle.
  setOnlySendOnceRelatedFieldsReadonlyTo(value) {
    let controller = this;
    this.onlySendOnceRelatedFields.forEach((field) => {
      controller.setReadOnlyAndStyle((field), value);
    });
  }

  // This is an event handler that is added to fields related to the "Only send once" checkbox. It short-circuits
  //  click events on a field if the field is set to `readOnly`.
  disableClicksIfReadOnlyHandler(event) {
    if (event.currentTarget.readOnly) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  setReadOnlyAndStyle(target, readOnly) {
    target.readOnly = readOnly;
    readOnly ? target.classList.add("disabled") : target.classList.remove("disabled");
  }

  // A bummer function to convert what Rails considers to be the correct number of seconds for an interval to
  //  the interval Moment needs to add the interval appropriately.
  //  See: https://stackoverflow.com/questions/66280102/why-does-adding-a-duration-in-seconds-to-a-moment-date-give-weird-results-for-va
  addRailsSecondsStringToMoment(seconds_string, moment) {
    switch (seconds_string) {
      case "86400":
        moment.add(1, "days");
        break;
      case "259200":
        moment.add(3, "days");
        break;
      case "604800":
        moment.add(7, "days");
        break;
      case "1209600":
        moment.add(14, "days");
        break;
      case "2629746":
        moment.add(1, "months");
        break;
      case "7889238":
        moment.add(3, "months");
        break;
      case "15778476":
        moment.add(6, "months");
        break;
      case "31556952":
        moment.add(1, "years");
        break;
    }
  }
}
