/*
 * Controller for the view at `views/engage/shared/_discussions.html.erb`.
 * Converted from old JS.
 * NOTE: The POSTing functionality really should be converted to Turbo if we're going to keep this around.
 * For now, I am just converting the old JS file to Stimulus because I think we will be getting rid of this
 * feature eventually.
 */

import { Controller } from "@hotwired/stimulus";
import { FetchRequest } from "@rails/request.js";
import timestampFormatter from "../../src/lib/timestampFormatter";

export default class extends Controller {

  static targets = ["submitButton", "commentTextArea", "tableBody", "errorsDiv"];

  static values = {
    postPath: String,
    userInfo: String,
  };

  updateSubmitButtonDisabledAttribute() {
    this.submitButtonTarget.disabled = !this.commentTextAreaTarget.value.trim();
  }

  submitIfEnterKeyPressedButShiftNotHeld(event) {
    if (event.keyCode === 13 && !event.shiftKey) {
      this.createComment(event);
    }
  }

  // Posts the comment if the comment text area has non-whitespace characters.
  // Disables the submit button during the duration of the process.
  async createComment(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!this.commentTextAreaTarget.value.trim()) return;

    this.errorsDivTarget.innerHTML = "";
    this.submitButtonTarget.disabled = true;

    const request = new FetchRequest("POST", this.postPathValue, {
      body: { comment: this.commentTextAreaTarget.value },
      responseKind: "json"
    });
    const response = await request.perform();
    const data = await response.json;
    if (response.ok) {
      this.commentTextAreaTarget.value = "";
      this.tableBodyTarget.innerHTML +=
        `
            <tr class='discussion-comment-row'>
              <td>
                ${this.userInfoValue}:
                <br>
                <span class='time'>${timestampFormatter(data.created_at)}</span>
              </td>
              <td>
                ${data.comment}
              </td>
            </tr>
        `;
    } else {
      console.error("Error posting discussion comment.");
      this.errorsDivTarget.innerHTML +=
        `
          <div class='discussion-warning'>
            ${data.error}
          </div>
        `;
    }

    this.submitButtonTarget.disabled = false;
  }
}
