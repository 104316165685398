import moment from 'moment-timezone'

const short_datetime = 'MM/DD/YYYY [at] hh:mm A'

const timezone_meta = document.head.querySelector("meta[name='agency_time_zone']")

// Set moment timezone when specified
if (timezone_meta) moment.tz.setDefault(timezone_meta.content)

export default function timestampFormatter(timestamp, format = short_datetime) {
  const dateTime = moment(timestamp)
  return dateTime.isValid() ? dateTime.format(format) : timestamp
}

export function unixFormatter(int, format = short_datetime) {
  const timestamp = moment.unix(int)
  return timestamp.isValid() ? timestamp.format(format) : timestamp
}
