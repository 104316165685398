import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = [ "clickDiv", "dropdownContent" ];

  initialize() {
    this.boundHandleClick = this.handleClick.bind(this);
  }

  connect() {
    document.addEventListener("click", this.boundHandleClick);
  }

  disconnect() {
    document.removeEventListener("click", this.boundHandleClick);
  }

  handleClick(event) {
    if (this.clickDivTarget.contains(event.target)) {
      this.toggleVisibility();
    } else {
      this.hide();
    }
  }

  hide() {
    if (!this.dropdownContentTarget.classList.contains("hidden")) {
      this.dropdownContentTarget.classList.add("hidden");
    }
  }

  toggleVisibility() {
    this.dropdownContentTarget.classList.toggle("hidden");
  }
}
