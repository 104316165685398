/*
 * Small JS insert to set the autofocus to the end of the input rather than the beginning. It's using the connect method
 *   instead of something more verbose since autofocus should only be on page load.
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    length = this.element.value.length;
    this.element.setSelectionRange(length, length);
  }
}
