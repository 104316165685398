/**
 * Controller for the loading indicator on datatables. The built in loading indicator for datatables sucks, so we
 * built this. This controller can be repurposed or deleted when we eliminate datatables as a dependency.
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  static targets = [ "table", "loadingContainer", "loadingText" ];

  initialize() {
    this.boundShowLoading = this.showLoading.bind(this);
    this.boundHideLoading = this.hideLoading.bind(this);
  }

  connect() {
    this.showLoading();
    this.tableTarget.addEventListener("datatableProcessingStart", this.boundShowLoading);
    this.tableTarget.addEventListener("datatableProcessingDone", this.boundHideLoading);
  }

  disconnect() {
    clearInterval(this.timerInterval);
  }

  showLoading() {
    this.loadingTextTarget.innerHTML = "Crunching data, please wait...";
    this.loadingContainerTarget.classList.remove("hidden");

    // Element may not exist on initial load, so use safe navigation.
    document.getElementsByClassName("dataTables_wrapper")[0]?.classList?.add("hidden");

    this.secondsPassed = 0;
    this.timerInterval = setInterval(() => {
      switch (this.secondsPassed) {
        case 5:
          this.loadingTextTarget.innerHTML = "Loading more data. Thank you for waiting.";
          break;
        case 15:
          this.loadingTextTarget.innerHTML = "Still loading data. Thank you for waiting.";
          break;
      }
      this.secondsPassed++;
    }, 1000);
  }

  hideLoading() {
    this.loadingContainerTarget.classList.add("hidden");
    document.getElementsByClassName("dataTables_wrapper")[0].classList.remove("hidden")
    clearInterval(this.timerInterval);
  }
}
