/*
 * Allows selecting a date range using browser-native controls.
 */

import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = [ "startDateField", "endDateField", "divisionIdField" ];

  // Would be great to do this without a button press, but unfortunately the date change event fires immediately when
  // the date changes and **does not wait** for the picker to be actually closed. This makes it unusable here because
  // it causes a full page reload. This could be worked around via Turbo Frames if we had more dev time.
  update(event) {
    event.preventDefault();
    event.stopPropagation();
    let new_search_params = `&start_date=${this.startDateFieldTarget.value}` +
                            `&end_date=${this.endDateFieldTarget.value}`;
    if (this.hasDivisionIdFieldTarget) {
      new_search_params += `&division_id=${this.divisionIdFieldTarget.value}`;
    }
    window.location.search = new_search_params;
  }
}
