/*
 * Put stuff into different tabs. Display the first tab by default. User can switch between tabs to see the other stuff.
 */

import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = [ "tabLink", "tabContent" ];

  static values = {
    displayedTabIndex: { type: Number, default: 0 },
  };

  selectTab(event) {
    event.preventDefault();
    event.stopPropagation();
    this.displayedTabIndexValue = this.tabLinkTargets.indexOf(event.currentTarget);
  }

  displayedTabIndexValueChanged() {
    this.render();
    this.markTabLinkSelected(this.tabLinkTargets[this.displayedTabIndexValue]);
  }

  render() {
    this.tabContentTargets.forEach((element, index) => {
      if (index === this.displayedTabIndexValue) {
        element.classList.remove("print-only");
      } else {
        element.classList.add("print-only");
      }
    });
  }

  markTabLinkSelected(tabLinkElement) {
    const selectedCSSClasses = ["text-slate-100", "bg-sky-700"];
    this.tabLinkTargets.forEach((element) => {
      element.classList.remove(...selectedCSSClasses);
    });
    tabLinkElement.classList.add(...selectedCSSClasses);
  }
}
