/*
 * Controller for the form in `views/native_root_admin/agency/monitors/_form.html.erb`.
 */
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "threshold", "thresholdStatic", "autoTuneEnabled" ];

  connect() {
    // Adding an additional call on connect due to validations not properly resetting the toggle on page load
    this.toggleThreshold();
  }

  toggleThreshold() {
    const autoTuneEnabled = this.autoTuneEnabledTarget.checked;
    this.thresholdTarget.disabled = autoTuneEnabled;
    this.thresholdTarget.hidden = autoTuneEnabled;
    this.thresholdStaticTarget.hidden = !autoTuneEnabled;
  }
}
