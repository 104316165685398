import BaseController from "./base_controller";

export default class extends BaseController {
  static targets = [ "editor", "hiddenField", "counter" ];

  static values = {
    variables: Object,
    editorIntitialized: Boolean,
    maxCharacters: Number,
  }

  initialize() {
    App.$quill.register("modules/counter", Counter, true);

    // Don't re-initialize the editor if it's already initialized, such as if the editor is rendered via a Turbo cache.
    // A bit hacky, but see: https://spidrtech.atlassian.net/browse/EDC-7078
    if (this.editorIntitializedValue) return;

    // Need a map so we can ensure order, since the items in this map need to match up with the keys added to the
    //  "Insert variable" button in `toolbarOptions`.
    this.variablesMap = new Map(Object.entries(this.variablesValue));

    // Render the editor **after** setting `this.variablesMap`, since `renderEditor()` checks that value.
    this.renderEditor();

    // Need to manually supply the HTML content of "Insert Variable" dropdown.
    const variablePickerItems = Array.prototype.slice.call(document.querySelectorAll(".ql-variable .ql-picker-item"));
    let i = 0;
    for (const [text, label] of this.variablesMap.entries()) {
      variablePickerItems[i].innerHTML = `${label}`;
      i++;
    }
    document.querySelector(".ql-variable .ql-picker-label").innerHTML
      = "Insert variable" + document.querySelector(".ql-variable .ql-picker-label").innerHTML;

    this.editorIntitializedValue = true;
  }

  renderEditor() {
    // Configure what buttons are available on the editor.
    const toolbarOptions = {
      container:
        [
          [{ "variable": [... this.variablesMap.keys() ] }], // Variable picker
        ],
      handlers: {
        "variable": function (value) {
          if (value) {
            // Add variable value to editor at current cursor position.
            const cursorPosition = this.quill.getSelection().index;
            this.quill.insertText(cursorPosition, value);
            this.quill.setSelection(cursorPosition + value.length);
          }
        }
      }
    };

    // Initialize the editor.
    let editor = new App.$quill(this.editorTarget, {
      theme: "snow",
      modules: {
        counter: {
          container: this.counterTarget,
          limit: this.maxCharactersValue,
        },
        toolbar: toolbarOptions,
      },
      formats: [
        // For now, disable all formats, since Quill is only used for Campaign text messages.
        // Later, if we adopt Quill other places, we will probably want to still disable certain formats, like
        // images and vide.
        // There isn't a better way to disable certain things. You gotta pass in the entire list, excluding
        // (or commenting out) the things you don't want.
        // @see https://quilljs.com/docs/formats/
        // NOTE: Check above link when you go to enable this. Ensure that no formats below have been added or removed.
        // "background", "bold", "color", "font", "code", "italic", "link", "size", "strike", "script",
        // "underline", "blockquote", "header", "indent", "list", "align", "direction", "code-block", "formula",
        // "image", "video",
      ],
    });

    // Populate the hidden form field with the editor content on submit, so that the editor's content is submitted with
    //  the form. Need to generate an ID for the hidden field to reference it, since we are attaching a function to the
    //  parent form which will not be able to reference Stimulus targets. However, since JS creates a closure every
    //  time a function is created, the below `randomHiddenFieldId` variable _will_ be referencable.
    const randomHiddenFieldId = this.randomString(40);
    this.hiddenFieldTarget.id = randomHiddenFieldId;
    this.editorTarget.closest("form").onsubmit = function() {
      document.getElementById(randomHiddenFieldId).value = editor.root.innerHTML;
    }
  }
}

// Quill plugin for character counting. This solution could probably be consolidated with the code used for the
// character count limit on the public-faing survey-taking view. But this is fine until we move that page to the
// new front-end.
class Counter {
  constructor(quill, options) {
    this.quill = quill;
    this.container = options.container;
    this.limit = options.limit;
    this.quill.on("text-change", this.update.bind(this));
    this.update();
  }

  characterCount() {
    return this.quill.getLength() - 1; // Seems like there's a ghost character in there somewhere.
  }

  update(_, oldContents) {
    if (this.limit) {
      // Don't allow adding text past the character limit.
      if (this.characterCount() > this.limit) {
        this.quill.setContents(oldContents);
      }
      // Update the character counter.
      this.container.innerText = `${this.characterCount()}/${this.limit}`;
      // Special appearance for the character counter if we're at the max character count.
      const cssClasses = ["font-bold", "text-red-700"];
      if (this.characterCount() === this.limit) {
        this.container.classList.add(...cssClasses);
        this.container.innerText += " character limit reached!"
      } else {
        this.container.classList.remove(...cssClasses);
        this.container.innerText += " characters allowed."
      }
    }
  }
}
