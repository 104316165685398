/*
 * Handles changing the message template editor between format modes (email, SMS).
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  changeFormat(event) {
    if (window.getSelection().toString().length === 0) {
      window.location.href = event.currentTarget.options[event.currentTarget.selectedIndex].value;
    }
  }
}
