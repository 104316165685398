/*
 * Handles copying to clipboard
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "source", "tooltip" ];

  connect() {
    if (!this.hasTooltipTarget) {
      return;
    }
    this.originalContent = this.tooltipTarget.innerHTML;
  }

  copy(event) {
    event.preventDefault();

    const value = this.sourceTarget.innerHTML || this.sourceTarget.value;
    navigator.clipboard.writeText(value).then(() => this.afterCopy());
  }

  afterCopy() {
    if (!this.hasTooltipTarget) {
      return;
    }

    if (this.timer) {
      clearTimeout(this.timer);
    }

    this.tooltipTarget.innerHTML = "Copied!";

    this.timer = setTimeout(() => {
      this.tooltipTarget.innerHTML = this.originalContent;
    }, 2000)
  }
}
