/*
 * Hides an element when an input has a certain value.
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "hide", "input" ];

  static values = {
    hideOn: String,
  };

  initialize() {
    this.toggleHide();
  }

  toggleHide() {
    if (this.inputTarget.value === this.hideOnValue) {
      this.hideTarget.classList.add("hidden");
    } else {
      this.hideTarget.classList.remove("hidden");
    }
  }
}
