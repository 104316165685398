/*
 * Powers an alert div that can be dismissed permanently.
 * Would be much more slick to do banner alerts with Turbo, but this is a quick solution for now.
 * We just need a banner to appear on the new FE, and don't have time to build out a full system right now.
 */

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    name: String,
  };

  initialize() {
    this.updateVisibility();
  }

  storageName() {
    return `dismissable-alert-${this.nameValue}`;
  }

  updateVisibility() {
    if (localStorage.getItem(this.storageName())) {
      this.element.classList.add("hidden");
    } else {
      this.element.classList.remove("hidden");
    }
  }

  dismiss(event) {
    event.preventDefault();
    event.stopPropagation();
    localStorage.setItem(this.storageName(), "dismissed");
    this.updateVisibility();
  }
}
