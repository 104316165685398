/*
 * Controller for the welcome banner on Spotlight.
 */

import { Controller } from "@hotwired/stimulus";
import moment from "moment";

export default class extends Controller {
  static values = {
    name: String,
  };

  initialize() {
    this.initialMoment = moment();
    this.timeOfDay = "";
    if (4 <= this.initialMoment.hour() && this.initialMoment.hour() < 12) {
      this.timeOfDay = "morning";
    } else if (12 <= this.initialMoment.hour() && this.initialMoment.hour() < 17) {
      this.timeOfDay = "afternoon";
    } else {
      this.timeOfDay = "evening";
    }
    this.element.innerHTML = `Good ${this.timeOfDay}, ${this.nameValue}.`;
  }
}
